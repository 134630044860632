.faq-style-wrapper {
    margin-top: 2em;
}

.faq-style-wrapper .faq-title {
    font-size: x-large;
    color:aqua;
    padding: 0px 0px 15px;
}

.faq-style-wrapper .faq-body .faq-row {
    padding: 0.75em 0;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
    font-size: larger;
    color: grey;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
    padding: 2em !important;
    font-size: medium !important;
    color: #6372ff !important;
}

.row-option {
    margin-top: 2em;
}

.row-option label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
}

.row-option input {
    padding: 0.5em;
    width: 2em;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-align: center;
}
.styles_faq-row-wrapper__3vA1D h2{
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-align: center;
    
}
.faq-style-wrapper{
    padding :15px 35px;

}
.section-titlee{
    text-align: center;
}


