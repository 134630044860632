/* .footerr {
    height: 400px;
    text-align: center;
    padding: 20px;
    background-color: rgb(223, 223, 223);
} */

.bot {
    bottom: 0;
    right : 0;
    position: relative;
    width: 50px;
}
.btnn {
    font-size: 50px;
    float: right;
    width: 40%;
    height: 50%;
    padding: 0px;
   
    
    color: beige;
    border-radius: 47%;
}
.btnnn {
    font-size: 30px;
    float: right;
    width: 30%;
    height: 50%;
    padding: 0px;
    
    
   
}

